// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers/

import Example from "https://framer.com/m/framer/Example.js@^1.0.0"

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function Pricing_Form(props) {
    // This is a React component containing an Example component
    // - Replace <Example /> with your own code
    // - Find inspiration: https://www.framer.com/developers/

    return (
        <form
            // here you have to paste your individual link from f.e. formspark
            action="https://submit-form.com/0XxLhsMZ"
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                fontFamily: "Inter, sans-serif",
                fontWeight: "600",
                fontSize: "14px",
                color: "#232323",
                padding: "32px",
                backgroundColor: "white",
                borderRadius: "32px",
                boxShadow: "0 2 4px 0 rgba(0, 0, 0, 0.25)",
                border: "1px solid #C8C8C8",
            }}
        >
            <h1
                style={{
                    paddingBottom: "16px",
                    color: "#003049",
                    fontSize: "32px",
                }}
            >
                Request a demo
            </h1>
            <input
                type="hidden"
                name="_redirect"
                // here you have to paste link, where do you want to lead your users to
                value="https://applausehq.com/fb-landing-page-success"
            />
            <label for="name" style={{ marginBottom: "8px" }}>
                Full name*
            </label>
            <input
                type="text"
                id="name"
                name="name"
                placeholder="Michael Scott"
                required
                style={{
                    width: "100%",
                    padding: "16px",
                    marginBottom: "24px",
                    border: "1px solid #C8C8C8",
                    borderRadius: "6px",
                    backgroundColor: "#F3F4F6",
                }}
            />
            <label for="email" style={{ marginBottom: "8px" }}>
                Email*
            </label>
            <input
                type="text"
                id="email"
                name="email"
                placeholder="name@company.com"
                required
                style={{
                    width: "100%",
                    padding: "16px",
                    marginBottom: "24px",
                    border: "1px solid #C8C8C8",
                    borderRadius: "6px",
                    backgroundColor: "#F3F4F6",
                }}
            />
            <label for="phonenumber" style={{ marginBottom: "8px" }}>
                Phone number*
            </label>
            <input
                type="text"
                id="number"
                name="number"
                placeholder="555-555-5555"
                required
                style={{
                    width: "100%",
                    padding: "16px",
                    marginBottom: "24px",
                    border: "1px solid #C8C8C8",
                    borderRadius: "6px",
                    backgroundColor: "#F3F4F6",
                }}
            />
            <label for="company" style={{ marginBottom: "8px" }}>
                Your company name*
            </label>
            <input
                type="text"
                id="company"
                name="company"
                placeholder="Dundler Mifflin"
                required
                style={{
                    width: "100%",
                    padding: "16px",
                    marginBottom: "24px",
                    border: "1px solid #C8C8C8",
                    borderRadius: "6px",
                    backgroundColor: "#F3F4F6",
                }}
            />
            <label for="locations" style={{ marginBottom: "8px" }}>
                Number of locations
            </label>
            <input
                type="integer"
                id="locations"
                name="locations"
                placeholder="2"
                style={{
                    width: "100%",
                    padding: "16px",
                    marginBottom: "24px",
                    border: "1px solid #C8C8C8",
                    borderRadius: "6px",
                    backgroundColor: "#F3F4F6",
                }}
            />
            <input
                type="submit"
                value="Submit"
                style={{
                    fontSize: "16px",
                    fontFamily: "Inter, sans serif",
                    width: "100%",
                    padding: "16px",
                    backgroundColor: "#0071D9",
                    color: "white",
                    fontWeight: "bold",
                    border: "none",
                    borderRadius: "6px",
                    cursor: "pointer",
                }}
            />
        </form>
    )
}

// Styles are written in object syntax
// Learn more: https://reactjs.org/docs/dom-elements.html#style
const containerStyle = {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
}
